export function showLoadingSpinner() {
  document.body.classList.add('wait');
  const spinner = document.querySelector('.spinner');
  if (!spinner) return;
  spinner.classList.remove('invisible', 'opacity-0');
  spinner.classList.add('opacity-90', 'bg-white');
  spinner.style.zIndex = 9999;
}

export function hideLoadingSpinner() {
  document.body.classList.remove('wait');
  const spinner = document.querySelector('.spinner');
  if (!spinner) return;
  spinner.classList.add('invisible', 'opacity-0');
}

import ApplicationController from '../../application_controller';
import { selectOption, deselectOption, activateNextButton, checkSingleChoice, checkMultiAnswer } from '../../utilities/questionnaire/answers_checker';

export default class extends ApplicationController {
  static targets = ["questionnaireContainer"];

  connect() {
    super.connect();
    this.answers = {};
    localStorage.setItem('questionnaireAnswers', '{}');
    this.userSignedIn = this.questionnaireContainerTarget.dataset.userSignedIn === "true";
    this.productId = this.questionnaireContainerTarget.dataset.productId;
    if (this.userSignedIn) {
      this._findAndKeepAnswers();
    }
  }

  initialize() {
    this.initializeQuestion();
    checkSingleChoice(this);
    checkMultiAnswer(this);
  }

  initializeQuestion() {
    const currentStep = this.questionnaireContainerTarget.dataset.currentStep;
    const currentQuestion = this.questionnaireContainerTarget.querySelector(`.spz-form-step.step-${currentStep}`);
    currentQuestion.classList.remove('hidden');
    if (currentStep > '1') {
      const firstQuestion = this.questionnaireContainerTarget.querySelector('.spz-form-step.step-1');
      firstQuestion.classList.add('hidden');
      const backButton = this.questionnaireContainerTarget.querySelector('.store-button--back');
      backButton.classList.remove('hidden');
    }
    this._disableSubmitButton();
  }

  selectMultipleAnswers(event) {
    const selectedLabel = event.target;
    const selectedInput = selectedLabel.parentNode.getElementsByTagName('input')[0];

    if (selectedInput.checked) {
      deselectOption(selectedLabel);
    } else {
      selectOption(selectedLabel);
    }

    this._saveAnswer(event.target, true);
    activateNextButton(this);
  }

  activateNextButton() {
    activateNextButton(this);
  }

  disableEnableSurveySubmit(event) {
    const firstActiveQuestion = event.target.closest('form').querySelector('.spz-form-step:not(.hidden)');

    const anyCheckedAnswers = firstActiveQuestion.querySelectorAll('.qa-radio:checked, .multi_answer_cb:checked, .doses-radio:checked').length

    if (anyCheckedAnswers) {
      activateNextButton(this);
    } else {
      this._disableSubmitButton();
    }
  }

  nextQuestion(event) {
    this._disableSubmitButton();

    const questionsForm = event.target.closest('form');
    const currentStep = questionsForm.dataset.currentStep;
    const isNextStep = questionsForm.dataset.questionsCount !== currentStep;

    if (isNextStep) {
      const nextStep = parseInt(currentStep) + 1;
      const currentStepElement = questionsForm.querySelector(`.spz-form-step.step-${currentStep}`);
      const nextStepElement = questionsForm.querySelector(`.spz-form-step.step-${nextStep}`);

      currentStepElement.classList.add('hidden');
      nextStepElement.classList.remove('hidden');
      this.disableEnableSurveySubmit(event);

      questionsForm.dataset.currentStep = nextStep;

      if (currentStep >= '1') {
        const backButton = questionsForm.querySelector('.store-button--back');
        backButton.classList.remove('hidden');
        this._modifyNextButton();
      }
      this.updateProgressBar(questionsForm.dataset.progressBarSteps, nextStep);
    } else {
      this._storeAnswersAndRedirect();
    }
  }

  updateProgressBar(registrationSteps, stepIndex) {
    const progressBar = document.querySelector('.checkout-progress__bar');
    const percentage = ((stepIndex) * 100 / (Number(registrationSteps) + 1)).toFixed(2);
    progressBar.style.width = `${percentage}%`;
  }

  previousQuestion(event) {
    const questionsForm = event.target.closest('form');
    const currentStep = parseInt(questionsForm.dataset.currentStep);

    if (currentStep > 1) {
      const previousStep = currentStep - 1;
      const currentStepElement = questionsForm.querySelector(`.spz-form-step.step-${currentStep}`);
      const previousStepElement = questionsForm.querySelector(`.spz-form-step.step-${previousStep}`);

      currentStepElement.classList.add('hidden');
      previousStepElement.classList.remove('hidden');

      questionsForm.dataset.currentStep = previousStep;
      this.disableEnableSurveySubmit(event)

      this.updateProgressBar(questionsForm.dataset.progressBarSteps, previousStep);

      if (previousStep === 1) {
        const backButton = questionsForm.querySelector('.store-button--back');
        backButton.classList.add('hidden');
        this._modifyNextButton();
      }
    }
  }

  toggleMultipleRadioButton(event) {
    const selected = $(event.target);
    const unselected = selected
      .closest('radiogroup')
      .find('input[type=radio]:not(checked)')
      .not(`#${selected.attr('id')}`);

   selected
      .attr('checked', 'checked')
      .prev()
      .addClass('!border-store-darkgray font-bold');
    unselected
      .removeAttr('checked')
      .prev()
      .removeClass('!border-store-darkgray font-bold');

    this._saveAnswer(event.target, false);
  }

  _findAndKeepAnswers() {
    const questionSections = this.questionnaireContainerTarget.querySelectorAll('.spz-form-step');
    questionSections.forEach(questionSection => {
      const checkedInputs = questionSection.querySelectorAll('input:checked');
      checkedInputs.forEach(input => {
        const questionId = input.closest('ul').dataset.questionId;
        const answerOptionId = input.value;

        if (!this.answers[questionId]) {
          this.answers[questionId] = [];
        }

        this.answers[questionId].push(answerOptionId);
      });
    });
    localStorage.setItem('questionnaireAnswers', JSON.stringify(this.answers));
  }

  _storeAnswersAndRedirect() {
    if (this.userSignedIn) {
      const baseFormUrl = this.questionnaireContainerTarget.action;
      const storeAnswersUrl = `${baseFormUrl}/store_pre_questionnaire_answers`

      fetch(storeAnswersUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ questionnaire_answers: this.answers })
      })
      .then(response => response.json())
      .then(data => {
        const nextPath = data.next_path;
        window.location.href = nextPath;
      }).catch(error => console.error('Error:', error));
    } else {
      window.location.href = '/users/sign_up';
    }
  }

  _disableSubmitButton() {
    const submitBtnEl = document.querySelector('.store-button.submit');
    submitBtnEl.disabled = true;
  }

  _saveAnswer(targetEl, isMultiple) {
    const questionId = targetEl.closest('ul').dataset.questionId;
    const answerOptionId = targetEl.value;

    if (!this.answers[questionId]) {
      this.answers[questionId] = [];
    }

    if (isMultiple) {
      if (targetEl.checked) {
        this.answers[questionId].push(answerOptionId);
      } else {
        this.answers[questionId] = this.answers[questionId].filter(id => id !== answerOptionId);
      }

      if (this.answers[questionId].length === 0) {
        delete this.answers[questionId];
      }
    } else {
      this.answers[questionId] = [answerOptionId];
    }

    localStorage.setItem('questionnaireAnswers', JSON.stringify(this.answers));
  }

  _modifyNextButton() {
    const currentStep = parseInt(this.questionnaireContainerTarget.dataset.currentStep);
    const nextBtn = this.questionnaireContainerTarget.querySelector('.store-button.spz-next');

    if (currentStep == 1) {
      nextBtn.classList.add('flex-1');
    } else if (currentStep > 1) {
      nextBtn.classList.remove('flex-1');
    }
  }
}

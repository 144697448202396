import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static values = {
    hasProducts: Boolean,
  };

  static targets = ['addressCard', 'addressForm'];

  connect() {
    super.connect();
    if (!this.hasProductsValue) return;

    this.modalCalled = false;
    this.originalAddress = this.captureAddressValues();
    document.addEventListener('click', this.handleCancelClick.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleCancelClick.bind(this));
  }

  initialize() {
    if (!this.hasProductsValue) return;

    this.addressInputs = document.querySelectorAll(
      'input[name^="store_checkout_form[ship_address_attributes]"], select[name^="store_checkout_form[ship_address_attributes]"]',
    );
    this.addressInputs.forEach((input) => {
      input.addEventListener('blur', this.handleBlur.bind(this));
    });
  }

  toggleAddressForm() {
    this.addressCardTarget.classList.toggle('hidden');
    this.addressFormTarget.classList.toggle('hidden');
  }

  handleCancelClick(event) {
    if (
      event.target.matches(
        '[data-action="click->store--checkout--checkout#cancelChange"]',
      )
    ) {
      this.cancelChange();
    }
  }

  handleBlur() {
    if (this.modalCalled || !this.hasAddressChanged()) return;

    this.modalCalled = true;

    this.callStimulusAction({
      cb: () =>
        this.stimulate(
          'Store::Cart::CheckoutAddressReflex#prefill_confirm_modal',
        ),
    });
  }

  hasAddressChanged() {
    return Array.from(this.addressInputs).some(
      (input) => input.value !== this.originalAddress[input.name],
    );
  }

  captureAddressValues() {
    return Array.from(this.addressInputs).reduce((values, input) => {
      values[input.name] = input.value;
      return values;
    }, {});
  }

  restoreAddressValues() {
    Object.entries(this.originalAddress).forEach(([name, value]) => {
      const input = document.querySelector(
        `input[name="${name}"], select[name="${name}"]`,
      );
      if (input) input.value = value;
    });
  }

  cancelChange() {
    this.restoreAddressValues();
    document.dispatchEvent(new CustomEvent('closeModal'));
  }
}
